@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	position: relative;
	z-index: 1;
	
	.main-contents {
		max-width: 1200px;
		margin: 0 auto;
		padding: 80px 100px;
		background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
		background-size: 20px;
		@include box-shadow;
		
		@media screen and (max-width: 1490px) {
			padding: 70px 80px;
			margin: 0 20px;
		}
	}
		
	&--column2 {
		.contents__inner {
			display: flex;
			justify-content: space-between;
			margin: 0 auto 125px;
			max-width: 1200px;
			
			@media screen and (max-width: 1490px) {
				margin: 0 0 125px 20px;
			}
			
			@media screen and (max-width: 959.5px) {
				display: block;
				margin: 0 20px 125px;
			}
			
		}
		.main {
			flex: 1 1 0%;
			min-width: 0;
			margin: 0 50px 0 0;
			padding: 60px 60px 80px;
			background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
			background-size: 20px;
			@include box-shadow;
			
			@media screen and (max-width: 959.5px) {
				margin: 0 0 40px;
			}
		}
		.side {
			flex: 0 0 auto;
			width: 230px;
			
			@media screen and (max-width: 959.5px) {
				width: 100%;
			}
		}
	}
}

.row {
	padding: 60px 0;
	
	&--green {
		background: $baseColorYanagi;
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.lead {
	color: $baseColorGreen;
	font-size: $xxl;
	font-weight: $font-medium;
	text-align: center;
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 45px;
	background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
	background-size: 70px;
	color: $white;
	font-size: 3.6rem;
	font-weight: $font-medium;
	line-height: 1.2;
	@include literal;
	position: relative;
	
	&::before ,
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
	}

	&::before {
		background: url(/common/img/common/bg_ttl_left.png) 0 0 no-repeat;
		background-size: contain;
		width: 139px;
		height: 194px;
		top: 25px;
		left: -17px;
	}
	
	&::after {
		background: url(/common/img/common/bg_ttl_right.png) 0 0 no-repeat;
		background-size: contain;
		width: 394px;
		height: 234px;
		top: 0;
		right: 0;
	}
	
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 1200px;
		height: 260px;
		margin: 0 auto;
		
		@media screen and (max-width: 1535px) {
			padding: 0 60px;
		}
		
		@media screen and (max-width: 1279.5px) {
			height: 200px;
		}
	}
	
	&__category-ttl {
		background: url(/common/img/common/bg_ttl_txt.png) 0 bottom repeat-x;
		background-size: 35px;
	}
	
	&__category-sub {
		font-size: $xl;
		font-weight: $font-regular;
		background: url(/common/img/common/bg_ttl_txt.png) 0 bottom repeat-x;
		background-size: 35px;
	}
}

.ttl02 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 40px;
	padding: 0 0 20px;
	color: $baseColorGreen;
	font-size: 3.0rem;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.3;
	@include literal;
	
	&::after {
		bottom: 0;
		content: '';
		width: 60px;
		border-bottom: 2px solid $baseColorGreen;
		@include centering-elements(true, false);
	}
	&__sub-ttl {
		color: $textColor;
		font-size: $l;
		font-weight: $font-regular;
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 30px;
	font-size: 2.6rem;
	font-weight: $font-medium;
	line-height: 1.4;
	text-align: center;
	@include literal;
	
	&::after {
		display: block;
		content: attr(data-text);
		margin: 5px 0 0;
		color: $baseColorGreen;
		font-size: $xxs;
		text-transform: uppercase;
		letter-spacing: 0;
		@include raleway;
	}
}

.ttl04 {
	margin: 0 0 25px;
	padding: 20px 0;
	border-top: 1px solid $baseColorGreen;
	border-bottom: 1px solid #d8dec3;
	color: $baseColorGreen;
	font-size: $xxl;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
	
	&.accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: $baseColor;
			font-size: $xxxl;
		}
	}
}

.ttl05 {
	margin: 0 0 30px;
	padding: 12px 20px;
	background: $baseColor;
	font-size: 1.9rem;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
	
	&.accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: $baseColor;
		}
	}
}

.ttl06 {
	margin: 0 0 20px;
	padding: 0 0 0 15px;
	border-left: 2px solid $baseColorGreen;
	color: $baseColorGreen;
	font-size: 1.7rem;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
}

.ttl07 {
	position: relative;
	margin: 0 0 30px;
	padding: 25px 0 0;
	color: $baseColorGreen;
	font-size: 3.0rem;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	@include literal;
	
	&::before {
		top: 0;
		display: block;
		content: '';
		width: 60px;
		height: 2px;
		background: $baseColorGreen;
		@include centering-elements(true, false);
	}
}

.ttl08 {
	margin: 0 0 30px;
	color: $orange;
	font-size: 2.6rem;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	@include literal;
}



/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;
	word-break: break-all;
	word-wrap: break-word;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -20px;
	 margin-right: -20px;

		&> * {
			margin-left: 20px;
			margin-right: 20px;
			width: calc((100% - 81px) / 2);
		}
		&--thin {
		 margin-left: -5px;
		 margin-right: -5px;
			
			&> * {
				margin-left: 5px;
				margin-right: 5px;
				width: calc((100% - 21px) / 2);
			}
		}
 }

.column3 {
	margin-left: -15px;
	margin-right: -15px;

	&> * {
		margin-left: 15px;
		margin-right: 15px;
		width: calc((100% - 91px) / 3);
	}
	&--thin {
	 margin-left: -6px;
	 margin-right: -6px;

		&> * {
			margin-left: 6px;
			margin-right: 6px;
			width: calc((100% - 37px) / 3);
		}
	}
}

.column4 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 81px) / 4);
	}
	&--thin {
	 margin-left: -5px;
	 margin-right: -5px;

		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 41px) / 4);
		}
	}
}

.column5 {
	margin-left: -5px;
	margin-right: -5px;

	&> * {
		margin-left: 5px;
		margin-right: 5px;
		width: calc((100% - 51px) / 5);
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	word-break: break-all;
	word-wrap: break-word;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	word-break: break-all;
	word-wrap: break-word;

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
	
	.img-column2 {
		display: flex;
		justify-content: space-between;
		
		* {
			margin: 0 10px;
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 5px;
			height: 5px;
			display: block;
			position: absolute;
			top: 0.75em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				left: 2px;
				width: 4px;
				height: 4px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: $baseColorGreen;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px 30px;
	box-sizing: border-box;
	background: $white;

	&--border-green {
		border: 2px solid $baseColorGreen;
	}
	&--green {
		background: $baseColor;
	}
	&--gray {
		background: #f3f3f3;
	}
	&--yellow {
		background: #f9f3d8;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 10px 15px;
		background: $white;
		border: 1px solid #dbdbdb;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-green {
		background: $baseColorGreen;
		color: $white;
	}
	.bg-green-lighten {
		background: $baseColor;
	}
	.bg-gray {
		background: #f6f6f6;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 205px;
	height: 50px;
	padding: 10px 25px;
	border-style: solid;
	border-width: 1px;
	color: $textColor;
	font-size: 1.7rem;
	text-align: center;
	line-height: 1.4;
	cursor: pointer;
	@include radius(0);
	@include transition;

	.icon-fix-left,
	.icon-fix-right {
		font-size: $xl;
		@include centering-elements(false, true);
	}
	.icon-fix-left {
		left: 10px;
	}
	.icon-fix-right {
		right: 10px;
	}
	&:hover {
		text-decoration: none;
	}
	
	&--green {
		background: $baseColorGreen;
		border-color: $baseColorGreen;
		color: $white;
		
		&:hover {
			background: $white;
			color: $baseColorGreen;
		}
	}
	&--black {
		background: #2a2a2a;
		border-color: #2a2a2a;
		color: $white;
		
		&:hover {
			background: $white;
			color: #2a2a2a;
		}
	}
	&--red {
		background: $red;
		border-color: $red;
		color: $white;
		
		&:hover {
			background: $white;
			color: $red;
		}
	}
	&--gray {
		background: $gray;
		border-color: $gray;
		
		&:hover {
			background: $white;
		}
	}
	&--orange {
		background: #ff7e00;
		border-color: #ff7e00;
		color: #fff;
		&:hover {
			background: #fff;
			color: #ff7e00;
		}
	}
	&--lg {
		min-width: 300px;
		height: 65px;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
		font-size: $m;
		
		.icon-fix-left,
		.icon-fix-right {
			font-size: $m;
		}
	}
	&--ss {
		min-width: inherit;
		padding: 0 10px 0 5px;
		height: 25px;
		font-size: 1.3rem;
		.icon-fix-right {
			font-size: 1.2rem;
			right: 3px;
		}
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			
			&.current,
			&:hover {
				background: $baseColorGreen;
				border: 1px solid $baseColorGreen;
				color: $white;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion ,
.sp-accordion {
	cursor: pointer;
	background: $baseColor;
	padding: 10px 20px;
	position: relative;
	
	&__icon {
		right: 10px;
		@include centering-elements(false, true);
		color: $baseColorGreen;
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
		.icon-add::before {
			content: "\e909";
		}
	}
}

.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	color: $white;
	border: 1px solid $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColorGreen;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\e905";
			color: $baseColorGreen;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 95px;
	height: 26px;
	background: $baseColorGreen;
	color: $white;
	font-size: $xs;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	padding: 0 10px;

	&--red {
		background: $red;
	}
	&--blue,
	&--graduates {
		background: $blue
	}
	&--pink,
	&--pta {
		background: $pink;
	}
	&--orange,
	&--student {
		background: $orange;
	}
	&--purple,
	&--club {
		background: $purple;
	}
	&--green,
	&--life {
		background: $green;
	}
	&--green-light {
		background: $baseColor;
	}
	&--gray {
		background: $gray;
		color: $textColor;
	}
	&--darkgray {
		background: $dark-gray;
	}
	&--sm {
		width: 26px;
	}
	&--md {
		height: 30px;
	}
	&--circle {
		@include circle(40);
	}
	&--tag {
		width: auto;
		height: 26px;
		margin: 0 2px 6px;
		padding: 0 15px;
		background: $white;
		border: 1px solid $baseColorGreen;
		color: $baseColorGreen;
		font-size: $xs;
		font-weight: $font-regular;
		@include transition;
	}
}
a.label--tag:hover {
	background: $baseColorGreen;
	border: 1px solid $baseColorGreen;
	color: $white;
	text-decoration: none;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	min-width: 0;

	&__item {
		display: flex;
		border-bottom: 1px solid $borderColor;
		padding: 15px;

		&:not(:last-child) {
			margin: 0 0 10px;
		}
		&__body {
			flex: 1 1 0%;
			min-width: 0;
		}
		&__date {
			flex: 0 0 auto;
			margin: 0 3% 0 0;
		}
		&__label {
			display: flex;
			margin: 0 0 6px;

			.label {
				min-width: 120px;
				width: auto;
				margin: 0 5px 0 0;
				padding: 0 8px;

				&--club-name {
					font-size: $xxs;
					font-feature-settings: 'palt';
					letter-spacing: .05em;
				}
			}
		}
		&__text {
			[href$=".pdf"]::after {
				display: inline-block;
				margin-left: .5em;
				color: #f00;
				content: "\f1c1";
				font-family: 'icomoon' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				vertical-align: middle;
			}
		}
	}
}


/* --------------------------------------------------- */
/* information-list,.movie-list */
/* --------------------------------------------------- */
.information-list,
.movie-list {
	min-width: 0;
	&__item {
		display: flex;
		border-bottom: 1px solid $borderColor;
		padding: 15px;

		&:not(:last-child) {
			margin: 0 0 10px;
		}
		&__date {
			flex: 0 0 auto;
			margin: 0 3% 0 0;
		}
		&__thum {
			margin: 0 20px 0 0;
			width: 140px;
			height: 108px;
			overflow: hidden;
			position: relative;
			& > img {
				width: auto;
				height: 100%;
				@include centering-elements(true, true);
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		text-align: right;
		margin: 0 0 40px;
		
		&__label {
			display: flex;
			justify-content: flex-end;
			flex-wrap: wrap;
			margin: 10px 0 50px;
			
			.label {
				margin: 0 3px 5px;
			}
		}
	}
	&__block {
		padding: 0 0 50px;
		border-bottom: 1px solid $borderColor;
		
		p {
			margin-bottom: 0;
		}		
		
		a {
			text-decoration: underline;
			
			&:hover {
				text-decoration: none;
				
				img {
					@include opacity;
				}
			}
		}
		
		.btn {
			text-decoration: none;
			&--pdf {
				margin: 0 2px 7px;
				i {
					margin-left: 5px;
				}
			}
		}
	}

	strong {
		font-weight: bold !important;
	}

	em {
		font-weight: bold !important;
		color: $red !important;
	}

	[data-lity="data-lity"] {
		display: block;

		&:hover {
			opacity: 0.7;
		}
	}

}


/* --------------------------------------------------- */
/* examination */
/* --------------------------------------------------- */
.examination {
	margin-top: 100px;
	padding: 50px 20px;
	background: $baseColorYanagi;
	text-align: center;
	
	&__inner {
		max-width: 1200px;
		margin: 0 auto;
	}
	
	.column {
		justify-content: center;
	}
	
	&__link {
		@include box-shadow;
		
		&:hover {
			@include opacity;
		}		
	}
}



/* --------------------------------------------------- */
/* news */
/* --------------------------------------------------- */
.news {
	&__content {
		display: flex;
		flex-wrap: wrap;
	}
}


/* --------------------------------------------------- */
/* news_index */
/* --------------------------------------------------- */
#news_index {
	.panel {
		&__inner {
			background: #f1f4e8;
			box-shadow: none;
		}
		&__img {
			background: $white;
		}
	}
}


/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	position: relative;
	display: block;
	margin-bottom: 50px;

	&__img {
		position: relative;
		padding-top: 73%;
		background: #fff;
		overflow: hidden;

		&::before,
		&::after {
			position: absolute;
			content: '';
			opacity: 0;
			visibility: hidden;
			@include transition;
		}
		&::before {
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			z-index: 1;
			border: 1px solid rgba($white, 0.4);
		}
		&::after {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($baseColor, .75);
		}

		img {
			/*
			width: auto !important;
			min-width: 100%;
			min-height: 100%;
			*/
			@include centering-elements(true, true);
			@include transition;
		}
		&__read {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			font-size: $m;
			opacity: 0;
			@include transition;
		}
		.label--red {
			position: absolute;
			bottom: 8px;
			right: 8px;
			max-width: calc(100% - 16px);
			padding: 4px 8px;
			color: $white;
			font-size: $xxs;
			font-feature-settings: 'palt';
			letter-spacing: 0.05em;
			line-height: 1.4;
		}
	}
	&__body {
		width: 100%;
		height: 115px;
		padding: 15px 20px;
		background: $white;
		color: $textColor;
		line-height: 1.6;
		@include transition;

		@media screen and (max-width: 1024px) {
			height: 85px;
			padding: 10px 20px;
		}
		&__date {
			margin: 0 0 5px;
			font-size: $xs;
		}
		&__ttl {
			font-weight: $font-medium;
			@include literal;

			.link-arrow__text {
				overflow: hidden;
				display: block;
				height: 3.2em;
			}

		}
	}

	&__icon {
		position: absolute;
		top: -15px;
		right: 0;
		z-index: 5;
		display: flex;
		width: 100%;
	}

	&:hover {
		text-decoration: none;

		.panel__img {
			img {
				transform: translate(-50%, -50%) scale(1.2);
			}
			&::before,
			&::after,
			&__read {
				opacity: 1;
				visibility: visible;
			}
		}
		.panel__body__date,
		.link-arrow__icon,
		.link-arrow__text {
			color: $baseColor;
		}
	}

	.label--circle {
		position: absolute;
		right: 10px;
		bottom: 92px;
	}
}




/* --------------------------------------------------- */
/* movie */
/* --------------------------------------------------- */
.movie {
	position: relative;
	cursor: pointer;
	@include transition;

	&::after {
		content: '';
		display: block;
		width: 55px;
		height: 55px;
		background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
		background-size: 55px;
		@include centering-elements(true, true);
	}

	&:hover {
		@include opacity;
	}
}

/* --------------------------------------------------- */
/* blog-list */
/* --------------------------------------------------- */
.blog-list {
	&__item {
		display: flex;
		align-items: center;
		padding: 15px 5px;
		/*border-bottom: 1px solid $baseColorLighten;*/
		line-height: 1.5;

		&:first-child {
			padding-top: 0;
		}

		&__date {
			flex: 0 0 120px;
			font-size: $s;
		}
		&__category {
			flex: 0 0 120px;
			margin: 0 15px 0 5px;
			padding: 3px 5px;
			background: $baseColor;
			color: #fff;
			font-size: $xs;
			text-align: center;
		}
		&__text {
			flex: 1 1 auto;
			a:hover {
				text-decoration: underline;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry-data */
/* --------------------------------------------------- */
.entry-data {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;
	margin: 0 0 45px;
	line-height: 1.4;

	&__category {
		margin: 0 10px 0 0;
		padding: 4px 8px;
		background: $baseColor;
		color: #fff;
		font-size: $s;
		line-height: 1.2;
	}

	&__date {
		display: block;
		font-size: $m;
		text-align: right;
		line-height: 1.2;
	}
	&__writen {
		margin: 0 0 0 20px;
	}
}



/* --------------------------------------------------- */
/* bnr-user */
/* --------------------------------------------------- */
.bnr-user {
	max-width: 1200px;
	margin: 0 auto;
	padding: 65px 0;
	
	@media screen and (max-width: 1515px) {
		padding: 65px 20px;
	}
	
	&__link {
		background: $white;
		@include box-shadow;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $textColor;
		font-size: $xl;
		line-height: 1.4;
		font-weight: $font-medium;
		width: 100%;
		height: 90px;
		padding: 0 20px;
		
		&:hover {
			@include opacity;
			text-decoration: none;
		}
	}
	
	&__img {
		width: 25%;
		color: #4A561C;
		font-size: 4.5rem;
		text-align: center;
		
		&--students { font-size: 4rem; }
		&--graduates { font-size: 5.2rem; }
		&--pta { font-size: 4.2rem; }
	}
	
	&__txt {
		width: 75%;
		
		&__english {
			color: $baseColorGreen;
			font-size: $xxs;
			display: block;
			font-weight: $font-regular;
		}
	}
}



/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	line-height: 1.5;
	
	&__ttl {
		&__link {
			display: flex;
			align-items: center;
			height: 100px;
			padding: 20px;
			background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
			background-size: 250px;
			color: $white;
			font-size: $xl;
			font-weight: $font-medium;
		}
	}
	&__item {
		background: $white;
		border: 1px solid $borderColor;
		border-top: none;
		
		&__link {
			display: flex;
			align-items: center;
			padding: 15px 15px 15px 18px;
			color: $textColor;
			
			[class^="icon-angle-"] {
				margin: 0 8px 0 0;
				color: $baseColorGreen;
			}
			.school_club .snav__item--school2 &,
			&.current,
			&:hover {
				background: $baseColor;
			}
		}
	}
	&__child {
		&__item {
			border-top: 1px solid $borderColor;

			&__link {
				display: flex;
				align-items: center;
				padding: 13px 15px 13px 30px;
				background: #f7f7f7;
				color: $textColor;
				font-size: $s;
				
				[class^="icon-angle-"] {
					margin: 0 8px 0 0;
					color: $baseColorGreen;
				}
				&.current {
					font-weight: $font-medium;
				}
				&:hover {
					background: $baseColor;
				}
			}
		}
	}
	
}



/* ============================================================ */
/* 在校生 配布物一覧 */
/* ============================================================ */
.zaikou,
.pta {

	.paginate {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 30px 5px 0;
		width: 70px;
		height: 40px;
		background: $baseColorGreen;
		color: $white;

		&:hover {
			border: 1px solid $baseColorGreen;
			background: $white;
			color: $baseColorGreen;
			text-decoration: none;
		}
	}
}


.pta {
	.column3 {
		.btn {
			min-width: auto !important;
			width: 100%;
		}
	}
	
	span.btn {
		cursor: default !important;
		
		&:hover {
			background: $baseColorGreen;
			border-color: $baseColorGreen;
			color: $white;
			cursor: default !important;
		}
	}
}
