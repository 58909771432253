@charset "utf-8";
/* ============================================================ */
/* learning */
/* ============================================================ */
.learning {
	.curriculum {
		text-align: center;
		
		&__img {
			border: 2px solid $baseColorGreen;
			display: block;
			position: relative;
			
			&::after {
				content: "\e90b";
				font-family: 'icomoon';
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
				background: $baseColorGreen;
				color: $white;
				padding: 0 5px;
				font-size: $xxs;
			}
		}		
	}
	
	.teacher {
		&__img {
			width: 60%;
			margin: 0 auto 20px;
			text-align: center;
		}
	}

	.learning__img {
		margin: 0 0 20px;
		display: block;
	}
	
}

#learning_preparatory {
	.btn-area {
        justify-content: center;
        margin: 0 10px 40px;
        & >* {
            text-align: center;
            position: relative;
        }
        &__link {
            width: 90%;
            margin: 0 auto;
            bottom: 20px;
            @include centering-elements(true, false);
            @include transition;
        }
    }
    .column>* img:not([class*=wd-a]) {
        width: auto;
        max-width: 100%;
    }
}


