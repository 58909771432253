@charset "utf-8";
/* ============================================================ */
/* exam-info */
/* ============================================================ */
#exam-info_index {
	.event-table {
		width: 100%;

		&__row {
			position: relative;
			display: block;
			padding: 15px 10px 50px;
			border-top: 1px solid $borderColor;
			background: $white;

			&:last-child {
				border-bottom: 1px solid $borderColor;
			}

			&__cell {
				&:nth-child(1) {
					font-weight: bold;
				}

				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5) {
					&>* {
						display: table-cell;
					}

					&::before {
						position: absolute;
						display: block;
						width: 75px;
						color: $white;
						text-align: center;
					}
				}
				
				&--app {
					position: absolute;
					right: 10px;
					top: 15px;

					.app-label {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 2px 10px;
						min-width: 100px;
						min-height: 25px;
						background: $white;
						color: inherit;
						font-size: $xs;
						font-weight: $font-medium;
						text-align: center;
						line-height: 1.4;
						position: relative;
						border-radius: 3px;
						border: 1px solid $white;
						text-decoration: none;
						&--gray {
							background: $gray;
							pointer-events: none;
						}
						&--red {
							background: $red;
							color: $white;
							pointer-events: none;
						}
						&--green {
							background: $baseColorGreen;
							color: $white;
							border-color: $baseColorGreen;
							&::after {
								font-family: 'icomoon' !important;
								content: "\f105";
								position: absolute;
								right: 5px;
							}
						}
						&--orange {
							background: $orange;
							color: $white;
							border-color: $orange;
							&::after {
								font-family: 'icomoon' !important;
								content: "\f105";
								position: absolute;
								right: 5px;
							}
						}
					}
				}
				&--blue::before { background: $blue; }
				&--gray::before { background: #A0A0A0; }
				&--red::before { background: $red; }
				&--jyunbi::before { background: #A0A0A0; }
				&--none::before { display: none !important; }
					
				&:nth-child(3)::before { content: "説明会"; right: 170px; bottom: 15px; }
				&:nth-child(4)::before { content: "校内見学"; right: 90px; bottom: 15px; }
				&:nth-child(5)::before { content: "個別相談"; right: 10px; bottom: 15px; }
				&--red::before { content: "受付中"; right: 10px; top: 15px; }
				&--jyunbi::before { content: "準備中"; right: 10px; top: 15px; }
			}
		}
	}
}