@charset "utf-8";
/* ============================================================ */
/* access */
/* ============================================================ */
#school_index {
	.slick-dots {
		font-size: 0;
		line-height: 0;
		
		li {
			width: 15px;
			height: 15px;
			
			button {
				width: 15px;
				height: 15px;
				padding: 0;
				
				&:before {
					content: '';
					width: 15px;
					height: 15px;
					opacity: 1;
					background: $white;
					border: 1px solid #cbd4b8;
					@include radius(50);
				}
			}
			
			&:hover,
			&.slick-active {
				button::before {
					background: $baseColorGreen;
					border: 1px solid $baseColorGreen;
				}
			}
		}
	}

	.schedule {
		display: flex;
		margin-bottom: 40px;
		
		&__month {
			flex: 0 0 auto;
			width: 85px;
			margin-right: 20px;
			border-right: 1px solid #cdcdcd;
			color: $baseColorGreen;
			font-size: 3.6rem;
			font-weight: $font-medium;
			
			&::after {
				content: "月";
				font-size: $xxl;
			}
		}
	}
}
