@charset "utf-8";
/* ============================================================ */
/* access */
/* ============================================================ */
#access_index {
	.station-link {
		display: inline-flex;
		align-items: center;
		padding: 5px 10px;
		background: $baseColorGreen;
		color: $white;
		font-size: $xxs;
	}
}