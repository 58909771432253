@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		max-width: none;
		padding: 0;
	}
	.row {
		&__inner {
			max-width: 1240px;
			margin: 0 auto;
			padding: 0 20px;
		}
	}
	
	.news-list {
		padding-left: 5%;
		border-top: none;
		
		&__item {
			padding: 0;
			margin: 0 0 25px;
			border-bottom: none;
		}
	}


	/* visual
	----------------------------------------------------------- */
	.visual {
		background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
		background-size: 70px;

		&__inner {
			max-width: 1200px;
			margin: 0 auto;
		}
		&__slider {
			width: 100%;
			
			&__item {
				position: relative;
				
				&__text {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 10px 20px;
					background: rgba(29,29,29, 0.85);
					color: $white;
					font-size: 2.6rem;
					font-weight: $font-medium;
					line-height: 1.5;
				}
			}
		}
		
		.slick-dotted.slick-slider {
			margin-bottom: 75px;
		}
		
		.slick-dots {
			font-size: 0;
			line-height: 0;
			
			li {
				width: 15px;
				height: 15px;
				
				button {
					width: 15px;
					height: 15px;
					padding: 0;
					
					&:before {
						content: '';
						width: 15px;
						height: 15px;
						opacity: 1;
						background: $white;
						border: 1px solid #cbd4b8;
						@include radius(50);
					}
				}
				
				&:hover,
				&.slick-active {
					button::before {
						background: $baseColorGreen;
						border: 1px solid $baseColorGreen;
					}
				}
			}
		}
	}
	
	/* entrance-exam
	----------------------------------------------------------- */
	.entrance-exam {
		display: flex;
		justify-content: center;
		margin: 60px 0 0 0;
		&__item {
			margin: 0 15px;
			@include box-shadow;
			&:hover {
				@include opacity;
			}
		}
	}
	
	/* emergency
	----------------------------------------------------------- */
	.emergency {
		border: 1px solid $red;
		background: $white;
		max-width: 1200px;
		margin: 0 auto -30px;
		padding: 0;
		
		@media screen and (max-width: 1515px) {
			margin: 0 20px -30px;
		}
		
		.flex-column {
			align-items: center;
		}
		
		.u-pc-mw17p {
			background: $red;
			color: $white;
			text-align: center;
			padding: 25px 0;
		}
		
		.news-list {
			border-left: none;
			padding: 15px 50px;
			
			&__item {
				align-items: center;
				
				&__date {
					font-weight: $font-medium;
				}
			}
		}
	}
	
	
	/* examination
	----------------------------------------------------------- */
	.examination {
		margin-top: 0;
	}
	
	
	/* sdh
	----------------------------------------------------------- */
	.sdh {
		max-width: 1200px;
		margin: 0 auto;
		padding: 65px 0;
		
		@media screen and (max-width: 1490px) {
			padding: 65px 20px;
		}
				
		&__inner {
			color: $textColor;
			
			&:hover {
				text-decoration: none;
				@include opacity;
				@include transition;
				
				.btn {
					background: $white;
					color: #2a2a2a;					
				}
			}
		}
		
		&__img {
			border: 5px solid $white;
		}
		
		&__txt {
			padding: 30px;
			padding-top: 20px;
			background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
			
			&__ttl {
				color: $baseColorGreen;
				font-size: $xxxl;
				font-weight: $font-medium;
				text-align: center;
			}

			.btn {
				min-width: auto;
				width: 205px;
				max-width: 100%;
			}
		}
	}
	
		
	/* news
	----------------------------------------------------------- */
	.news {
		
		&__filter {
			display: flex;
			justify-content: center;
			margin: 0 0 30px;

			&__item {
				position: relative;
				margin: 0 15px;
				padding: 0 0 5px;
				color: $textColor;

				&::before {
					position: absolute;
					left: 50%;
					bottom: 0;
					z-index: 5;
					content: "";
					width: 0;
					height: 3px;
					background: $baseColorGreen;
					backface-visibility: hidden;
					transform: translateX(-50%);
					@include transition;
				}
				
				&.active,
				&:hover {
					text-decoration: none;

					&::before {
						width: 100%;
					}
					
					&::after {
						top: 100%;
						left: 50%;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-color: rgba(127,145,57,0);
						border-top-color: #7f9139;
						border-width: 6px;
						margin-left: -6px;
					}
				}
			}
		}

		.panel {
			&__icon {
				.label {
					width: 100%;
				}
			}
		}


		.is-animated {
			animation: .6s fade-in;
		}
		
		.btn {
			width: 250px;
			height: 60px;
		}
	}
	
}