@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: #fdfefc;
	padding: 0 0 35px;

	/* fnav
	----------------------------------------------------------- */
	.fnav {
		padding: 55px 0 0;
		margin: 0 0 35px;
		line-height: 1.5;
		
		&__inner {
			display: flex;
			max-width: 1240px;
			margin: 0 auto;
			padding: 0 0 55px;
			border-bottom: 1px solid $borderColor;
			
			@media screen and (max-width: 1515px) {
				padding: 0 20px 55px;
			}
		}
		&__level1 {
			width: 25%;
			
			&__item {
				&:not(:last-child) {
					margin: 0 0 30px;
				}
				&__link {
					color: $textColor;
					font-size: $m;
					font-weight: $font-medium;
				}
			}
		}
		&__level2 {
			margin: 15px 0 0;
			
			&__item {
				&:not(:last-child) {
					margin: 0 0 3px;
				}
				&__link {
					color: $textColor;
					font-size: $xs;
				}
			}
		}

	}


	/* footer-content
	----------------------------------------------------------- */
	.footer-content {
		display: flex;
		align-items: flex-end;
		max-width: 1240px;
		margin: 0 auto;
		
		@media screen and (max-width: 1515px) {
			padding: 0 20px;
		}
		
		&__logo {
			display: flex;
			align-items: center;
			margin: 0 25px 0 0;
			width: 214px;
		}
		&__address {
			font-size: $xs;
			line-height: 1.6;
		}
		&__copyright {
			margin-left: auto;
			color: #8e8e8e;
			font-size: 1.1rem;
			@include raleway;
		}
	}
}