@charset "utf-8";
/* ============================================================ */
/* exam-info */
/* ============================================================ */
#exam-info_index {
	.event-table {
		display: table;
		width: 100%;
		border-collapse: collapse;
		
		&__row {
			display: table-row-group;
			background: $white;
			
			&__cell {
				display: table-cell;
				vertical-align: middle;
				padding: 15px 10px;
				border: 1px solid #dbdbdb;
				text-align: center;
				line-height: 1.4;
				
				&--green {
					background: $baseColor;
				}
				
				&--app {
					.app-label {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 5px 10px;
						background: $white;
						color: inherit;
						font-size: $xs;
						font-weight: $font-medium;
						text-align: center;
						line-height: 1.4;
						position: relative;
						border-radius: 3px;
						border: 1px solid $white;
						text-decoration: none;
						&--gray {
							pointer-events: none;
						}
						&--red {
							color: $red;
							pointer-events: none;
						}
						&--green {
							background: $baseColorGreen;
							color: $white;
							border-color: $baseColorGreen;
							&:hover {
								background: $white;
								color: $baseColorGreen;
								border-color: $baseColorGreen;
							}
							&::after {
								font-family: 'icomoon' !important;
								content: "\f105";
								position: absolute;
								right: 5px;
							}
						}
						&--orange {
							background: $orange;
							color: $white;
							border-color: $orange;
							&:hover {
								background: $white;
								color: $orange;
								border-color: $orange;
							}
							&::after {
								font-family: 'icomoon' !important;
								content: "\f105";
								position: absolute;
								right: 5px;
							}
						}
					}
				}


				
			}
		}
		
		& > a {
			text-decoration: none;
			
			&:hover {
				background: #F7F9F1;
			}
		}
	}
	
	.main {
		a[href^="tel:"] { 
			cursor: default; 
			
			&:hover {
				text-decoration: none;
			}
		}
	}
}
