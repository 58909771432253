@charset "utf-8";
/* ============================================================ */
/* learning */
/* ============================================================ */
.learning {
	.curriculum {
		text-align: center;
		
		&__img {
			border: 3px solid $baseColorGreen;
			display: block;
			position: relative;
			
			&::after {
				content: "\e90b";
				font-family: 'icomoon';
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
				background: $baseColorGreen;
				color: $white;
				padding: 0 8px;
				font-size: $l;
			}
		}		
	}
	
	.teacher {
		&__img {
			max-width: 160px !important;
		}
	}
	
	.learning__img {
		margin: 0 0 -80px;
		display: block;
		
		@media screen and (max-width: 1490px) {
			margin: 0 0 20px;
		}
	}
}


#learning_preparatory {
    .btn-area {
        justify-content: center;
        max-width: 770px;
        margin: 0 auto 80px;
        & >* {
            text-align: center;
            position: relative;
        }
        &__link {
            width: 90%;
            margin: 0 auto;
            bottom: 20px;
            @include centering-elements(true, false);
            @include transition;
            &:hover {
                width: 85%;
            }
        }
    }
    .column>* img:not([class*=wd-a]) {
        width: auto;
        max-width: 100%;
    }
}



