@charset "utf-8";
/* ============================================================ */
/* sotugyou */
/* ============================================================ */
#sotugyou_index {
	.shoumei-table {
		tbody {
			td {
				display: block;
				padding: 5px 10px;
				border: 0;
				
				&:nth-child(1) {
					padding: 10px;
					background: $gray;
				}
				&:nth-child(2) {
					padding-top: 10px;
					
					&:before {
						content: "発行にかかる日数：";
						color: $baseColorGreen;
						font-weight: bold;
					}
				}
				&:nth-child(3) {
					&:before {
						content: "発行手数料：";
						color: $baseColorGreen;
						font-weight: bold;
					}
				}
				&:last-child {
					padding-bottom: 10px;
				}

				&:empty {
					padding: 2.5px;
				}
			}
		}
	}
}
