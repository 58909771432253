@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	display: flex;
	flex-direction: column;
	background: #fdfefc;

	/* fnav
	----------------------------------------------------------- */
	.fnav {
		order: 1;
		border-top: 1px solid $borderColor;

		a {
			color: $textColor;
		}
		
		&__level1 {
			&__item {
				border-bottom: 1px solid $borderColor;
				
				&__link {
					display: flex;
					align-items: center;
					padding: 15px;
					
					[class^="icon-angle-"] {
						margin: 0 8px 0 0;
					}
					&.active {
						.icon-angle-down {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		
		&__level2 {
			display: none;
			background: #f7fbee;
			
			&__item {
				border-top: 1px dotted $borderColor;
				
				&__link {
					display: block;
					padding: 15px 15px 15px 32px;
					color: $baseColorGreen !important;
				}
			}
		}
		
	}


	/* footer-content
	----------------------------------------------------------- */
	.footer-content {
		order: 3;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 25px 0;
		
		&__logo {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 10px;
			width: 50vw;
		}
		&__address {
			margin: 0 0 15px;
			font-size: $xxs;
			text-align: center;
		}
		&__menu {
			display: flex;
			justify-content: center;
			margin: 0 0 15px;
			
			&__item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 0 5px;
				background: $baseColor;
				color: $baseColorGreen;
				font-size: $xxxs;
				@include circle(60);
				
				&__icon {
					color: $baseColorGreen;
					font-size: $l;
				}
			}
		}
		&__copyright {
			color: #8e8e8e;
			font-size: $xxxs;
			@include raleway;
		}
	}
	
}