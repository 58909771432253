@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		margin-left: 0;
		margin-right: 0;
	}
	.news-list {
		border-top: none;
		
		&__item {
			border-bottom: none;
			padding: 0;
			
			&:not(:last-child) {
				margin: 0 0 15px;
			}
		}
	}


	/* visual
	----------------------------------------------------------- */
	.visual {
		margin: 0 0 60px;
		
		&__slider {
			&__item {
				position: relative;
				
				&__text {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 7px 10px;
					background: rgba(29,29,29, 0.85);
					color: $white;
					font-size: 1.7rem;
					font-weight: $font-medium;
					line-height: 1.5;
					
					&__ttl {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}
		}
		
		.slick-dotted.slick-slider {
			margin-bottom: 40px;
		}
		.slick-dots {
			bottom: -30px;
			font-size: 0;
			line-height: 0;
			
			li {
				width: 15px;
				height: 15px;
				
				button {
					width: 15px;
					height: 15px;
					padding: 0;
					
					&:before {
						content: '';
						width: 15px;
						height: 15px;
						line-height: 5px;
						font-size: 1px;
						opacity: 1;
						background: $white;
						border: 1px solid #cbd4b8;
						@include radius(50);
					}
				}
				
				&:hover,
				&.slick-active {
					button::before {
						background: $baseColorGreen;
						border: 1px solid $baseColorGreen;
					}
				}
			}
		}
		
	}

	/* entrance-exam
	----------------------------------------------------------- */
	.entrance-exam {
		padding: 0 15px;
		&__item {
			display: block;
			margin-bottom: 15px;
			@include box-shadow;
		}
	}
	
	/* emergency
	----------------------------------------------------------- */
	.emergency {
		border: 1px solid $red;
		background: $white;
		margin: 0 15px 20px;
		padding: 0;
		
		.u-pc-mw17p {
			background: $red;
			color: $white;
			padding: 8px 20px;
		}
		
		.news-list {
			border-left: none;
			padding: 5px 20px 15px;
			
			&__item {
				align-items: center;
				
				&__date {
					font-weight: 600;
				}
			}
		}
	}
	


	/* sdh
	----------------------------------------------------------- */
	.sdh {
		&__inner {
			padding: 20px 15px;
			margin-bottom: 0;
			border-bottom: 1px solid #cbd4b8;
			display: flex;
			position: relative;
			color: $textColor !important;
				
			&::after {
				content: "\f105";
				font-family: 'icomoon';
				font-size: 3.6rem;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
			}
			
			&:last-of-type {
				border-bottom: none;
			}
		}
		
		&__img {
			width: 30%;
			
			.u-pc-mw100p {
				border: 3px solid $white;
			}
		}
		
		&__txt {
			width: 70%;
			padding: 0 20px 0 15px;
			
			&__ttl {
				color: $baseColorGreen;
				font-size: $l;
				font-weight: $font-medium;
				margin: 0 0 3px;
			}
		}

	}
		
	
	
	/* news
	----------------------------------------------------------- */
	.news {
		
		&__content {
			display: flex;
			flex-wrap: wrap;
		}
		
		.selectbox {
			position: relative;
			z-index: 5;
			margin: 0 0 25px;

			&__select {
				position: relative;
				display: flex;
				align-items: center;
				padding: 0 15px;
				height: 48px;
				background: $white;
				border: 1px solid $borderColor;
				color: $textColor;
				cursor: default;
				
				&__text {
					flex: 1 1 auto;
				}
				.icon-angle-down {
					flex: 0 0 auto;
				}
				&.select-focus {
					.icon-angle-down {
						transform: rotate(180deg);
					}
				}
			}
			
			&__pulldown {
				display: none;
				position: absolute;
				left: 0;
				top: 48px;
				width: 100%;
				background: $baseColorGreen;
				
				& > * {
					display: block;
					padding: 10px 15px;
					color: $white;
					
					&:not(:last-child) {
						border-bottom: 1px solid rgba($white, 0.15);
					}
					&.selected {
						background: #3f520d;
						color: $baseColor;
					}
				}
			}
		}

		.panel {
			&__icon {
				.label {
					width: 100%;
				}
			}
		}

	}	
	
	
	
}