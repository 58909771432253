@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	flex: 0 0 auto;
	background: $white;
	
	@media screen and (min-width: 1280px) {
		width: 255px;
		@include box-shadow;
		position: relative;
		z-index: 3;
	}
	@media screen and (max-width: 1279.5px) {
		display: flex;
		align-items: center;
		height: 80px;
		border-bottom: 1px solid $borderColor;
	}
	
	
	/* header-logo
	----------------------------------------------------------- */
	.header-logo {
		@media screen and (min-width: 1280px) {
			display: flex;
			align-items: center;
			height: 195px;
		}
		@media screen and (max-width: 1279.5px) {
			order: 2;
			margin-right: auto;
		}
		
		&__link {
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 175px;
			margin: 0 auto;

			@media screen and (max-width: 1279.5px) {
				flex-direction: row;
			}
			
			& > * {
				flex: 0 0 auto;
			}
			
			&__mark {
				width: 58px;
				margin: 0 0 3px;
				
				@media screen and (max-width: 1279.5px) {
					width: 40px;
					margin: 0 15px 0 0;
				}
			}
			&__txt {
				width: 160px;
				
				@media screen and (max-width: 1279.5px) {
					width: 140px;
				}
			}
			&__name {
				width: 160px;
			}
			&__houjin {
				width: 132px;
				
				@media screen and (max-width: 1279.5px) {
					margin: 0 0 -5px;
					display: block;
				}
			}
		}
	}


	/* hnav
	----------------------------------------------------------- */
	.hnav {
		@media screen and (max-width: 1279.5px) {
			order: 1;
			margin: 0 20px 0 0;
		}
		.hnav__item--menu {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			background: $baseColor;
			color: $baseColorGreen;
			font-size: 3.0rem;
			cursor: pointer;
			
			.hnav__item__text {
				display: none;
			}
			
			@media screen and (min-width: 1280px) {
				display: none;
			}
		}
	}


	/* gnav
	----------------------------------------------------------- */
	.gnav {
		@media screen and (max-width: 1279.5px) {
			position: absolute;
			top: 80px;
			bottom: 0;
			left: 0;
			z-index: 10;
			width: 255px;
			background: $white;
			border-right: 1px solid $borderColor;
			opacity: 0;
			visibility: hidden;
			transform: translateX(-100%);
			@include transition;
		}
		.show & {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
		
		&__content1 {
			margin: 0 0 30px;
			border-top: 1px solid $borderColor;
			
			&__body {
				&:hover {
					.gnav__content1__item {
						background: $baseColor;
						color: $baseColorGreen;
					}
					.gnav__content1__lower {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			&__item {
				display: block;
				padding: 15px 20px;
				border-bottom: 1px solid $borderColor;
				color: $textColor;
				cursor: pointer;
				@include transition;
				
				.icon-angle-right {
					color: $baseColorGreen;
				}
				
				&.current,
				&:hover {
					background: $baseColor;
					color: $baseColorGreen;
				}
				.about &--about,
				.learning &--learning,
				.course &--course,
				.life &--life,
				.exam &--exam,
				.access &--access {
					background: $baseColor;
					color: $baseColorGreen;
				}
			}
			&__lower {
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 10;
				left: 255px;
				width: 260px;
				padding: 210px 0 20px;
				background: rgba($baseColorGreen, 0.85);
				color: $white;
				opacity: 0;
				visibility: hidden;
				@include transition;
				
				@media screen and (max-width: 1279.5px) { padding: 20px 0; }

				.inner {
					position: fixed;
					top: 200px;
					width: 260px;
				}

				&__ttl {
					margin: 0 0 20px;
					font-size: $l;
					font-weight: $font-medium;
					
					&:not(:first-child) {
						margin-top: 50px;
					}
				}
				&__link {
					display: block;
					padding: 12px 20px;
					color: $white;
					line-height: 1.6;
					
					&.current,
					&:hover {
						background: rgba($white, 0.8);
						color: $baseColorGreen;
					}
				}
			}
		}
		
		&__content2 {
			margin: 0 20px 30px;
			
			&__item {
				display: flex;
				align-items: center;
				color: $textColor;
				font-size: $s;
				line-height: 1.6;
				
				.icon-angle-right {
					margin: 0 5px 0 0;
					color: #a03f2b;
				}
				
				&:not(:last-child) {
					margin: 0 0 10px;
				}
				&:hover {
					.gnav__content2__item__text {
						text-decoration: underline;
					}
				}
			}
		}
		
		&__content3 {
			margin: 0 20px;
			
			.bnr-link {
				justify-content: flex-start;
			}

		}
		
	}
}