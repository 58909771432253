@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?jbhr0e');
  src:  url('/common/icon/fonts/icomoon.eot?jbhr0e#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?jbhr0e') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?jbhr0e') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?jbhr0e') format('woff'),
    url('/common/icon/fonts/icomoon.svg?jbhr0e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pta:before {
  content: "\e90c";
}
.icon-graduates:before {
  content: "\e90d";
}
.icon-book:before {
  content: "\e90e";
}
.icon-zoom-in:before {
  content: "\e90b";
}
.icon-pc:before {
  content: "\e900";
}
.icon-pagetop:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-pen:before {
  content: "\e903";
}
.icon-student:before {
  content: "\e904";
}
.icon-check:before {
  content: "\e905";
}
.icon-user:before {
  content: "\e906";
}
.icon-mail2:before {
  content: "\e907";
}
.icon-filter_none:before {
  content: "\e908";
}
.icon-remove:before {
  content: "\e909";
}
.icon-add:before {
  content: "\e90a";
}
.icon-tag:before {
  content: "\f02b";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-phone:before {
  content: "\f095";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-lightbulb-o:before {
  content: "\f0eb";
}
.icon-plus-square:before {
  content: "\f0fe";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-minus-square:before {
  content: "\f146";
}
.icon-fax:before {
  content: "\f1ac";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
