@charset "utf-8";

$baseColor: #e7efd4;
$baseColorGreen: #7f9139;
$baseColorYanagi: #eff7de;
$textColor: #484848;
$borderColor: #e7e7e7;

$white: #fff;
$red: #d00000;
$blue: #2c6ead;
$pink: #e56e84;
$orange: #ec7811;
$purple: #a352a2;
$green: #156d22;
$gray: #dedede;
$dark-gray: #777;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin literal {
	font-feature-settings: "palt";
	letter-spacing: 0.04em;
}

@keyframes fade-in {
	0% { opacity: 0.1; } 
	100% { opacity: 1; }
}	

@mixin raleway {
	font-family: 'Raleway', sans-serif;
}

@mixin box-shadow {
	 box-shadow: 3px 3px 7px 2px rgba(39,39,39,0.1);
 }

/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;


/* font-weight
-------------------------------------------------- */
$font-regular: 400;
$font-medium: 500;
